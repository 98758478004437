<template>
  <div class="modal-overlay" @click="closeModal">
    <div class="modal-content" @click.stop>
      <h2>Edit Tags</h2>
      <textarea v-model="tagSetJson" rows="25" cols="60"></textarea>
      <div class="modal-buttons">
        <button @click="loadDefaultTags">Load Defaults</button>
        <div>
          <button @click="saveTags">Save</button>
          <button @click="closeModal">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { ProcessorService } from './ProcessorService';

export default defineComponent({
  name: 'TagEditorModal',
  props: {
    processorService: {
      type: Object as () => ProcessorService,
      required: true
    }
  },
  emits: ['close'],
  setup(props, { emit }) {
    const tagSetJson = ref('');

    onMounted(async () => {
      try {
        const response = await props.processorService.getTags();
        tagSetJson.value = JSON.stringify(response.tagSet, null, 2);
      } catch (error) {
        console.error('Error fetching tags:', error);
        alert('Error fetching tags.' + error);
      }
    });

    const loadDefaultTags = async () => {
      try {
        const response = await props.processorService.getDefaultTags();
        tagSetJson.value = JSON.stringify(response.tagSet, null, 2);
      } catch (error) {
        console.error('Error loading default tags:', error);
        alert('Failed to load default tags. Please try again.');
      }
    };

    const saveTags = async () => {
      try {
        const parsedTags = JSON.parse(tagSetJson.value);
        await props.processorService.setTags(parsedTags);
        emit('close');
      } catch (error) {
        console.error('Error saving tags:', error);
        alert('Invalid JSON format. Please check your input.');
      }
    };

    const closeModal = () => {
      emit('close');
    };

    return {
      tagSetJson,
      saveTags,
      loadDefaultTags,
      closeModal
    };
  }
});
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 80%;
  max-height: 80%;
  width: 80%;
  height: 80%;
  overflow-y: auto;
  z-index: 2001;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

textarea {
  flex-grow: 1;
  width: 100%;
  margin-bottom: 10px;
  resize: none;
  box-sizing: border-box;
  padding: 8px;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
</style>