<template>
  <!-- Main app container -->
  <div id="dev-chat" @click="handleOutsideClick">
    <div v-if="showTokenAlert" class="api-key-alert">
      OpenAI token is not set in settings. Please set it to use the chat functionality.
    </div>
    <!-- Menu toggle button -->
    <div class="menu-toggle" @click.stop="toggleMenu">☰</div>
    
    <!-- Sidebar menu -->
    <div class="sidebar" :class="{ 'open': isMenuOpen }" @click.stop>
      <button @click="editTags">Edit Tags</button>
      <button @click="editCorporateAI">Edit Corporate AI</button>
      <button @click="openSettings">Settings</button>
      <button @click="openFilesModal">Manage Files</button>
      <button @click="reset">Reset Chat</button>
      <!-- Audio streaming toggle button -->
      <button @click="toggleAudioStreaming" class="audio-stream">
        {{ audioManager.streaming ? 'Stop Audio' : 'Start Audio' }}
      </button>
    </div>
    
    <!-- Main chat area -->
    <div id="chat">
      <div class="chat-content" ref="chatContent">
        <!-- List of chat messages -->
        <ul class="chat-messages">
          <li v-for="message in messages" :key="message.id">
            <div class="message"
              :class="{ 'user-message': message.role === Role.User, 'bot-message': message.role === Role.Bot }">
              <div v-html="renderMarkdown(message.text)"></div>
              <!-- Display tags if present -->
              <div class="tags" v-if="message.tags && message.tags.length">
                <span class="tag" v-for="tag in message.tags" :key="tag">{{ tag }}</span>
              </div>
              <!-- Add info icon for messages with traceInfo -->
              <div v-if="message.traceInfo && message.traceInfo.length" class="trace-info-icon" @click.stop="openTraceInfo(message)">
                ℹ️
              </div>
            </div>
          </li>
        </ul>
        <!-- Trigger System 2 button -->
        <button v-if="showTriggerSystem2Button" @click="triggerSystem2" class="trigger-system-2-button" :disabled="showTokenAlert">
          Trigger System 2
        </button>
        <!-- Loading indicator -->
        <div v-if="isLoading" class="loading-message">
          <div class="loading-indicator"></div>
          AI is thinking...
        </div>
      </div>
      <!-- Chat input area -->
      <div class="chat-input">
        <input type="text" v-model="newMessage" placeholder="Type your message..." @keyup.enter="sendMessage" :disabled="showTokenAlert"/>
        <button @click="sendMessage" :disabled="showTokenAlert">Send</button>
      </div>
    </div>

        <!-- Modals -->
        <TagEditorModal v-if="isTagEditorOpen" :processorService='chatService as ProcessorService'
     @close="closeTagEditor" />
     <CorporateAIEditorModal v-if="isCorporateAIEditorOpen" :processorService='chatService as ProcessorService'
     @close="closeCorporateAIEditor" />
    <SettingsModal v-if="isSettingsOpen" @close="closeSettings" @save="saveSettings" />
    <FilesModal v-if="isFilesModalOpen" :processorService='chatService as ProcessorService' @close="closeFilesModal" />


    <!-- TraceInfo side panel -->
    <div v-if="showTraceInfo" class="trace-info-panel">
    <button class="close-button" @click="closeTraceInfo">&times;</button>
    <h3>Trace Information</h3>
    <ul class="trace-messages">
      <li v-for="(traceMessage, index) in currentTraceInfo" :key="index">
        <div class="trace-message" :class="{ 'user-message': traceMessage.role === 'user', 'assistant-message': traceMessage.role === 'bot' }">
          <strong>{{ traceMessage.role }}:</strong>
          <div v-html="renderMarkdown(traceMessage.text)"></div>
        </div>
      </li>
    </ul>
  </div>
</div>

</template>

<script lang="ts">
import { defineComponent, ref, nextTick } from 'vue';
import { ProcessorService } from './ProcessorService';
import { Preprocessor } from "./genericPreprocessor";
import { Message, Role, Settings } from './types';
import TagEditorModal from './TagEditorModal.vue';
import CorporateAIEditorModal from './CorporateAIEditorModal.vue';
import SettingsModal from './SettingsModal.vue';
import FilesModal from './FilesModal.vue';
import { useToast } from 'vue-toast-notification';
import { AudioManager } from './AudioManager';
import MarkdownIt from 'markdown-it';
import './DevChat.vue.css';


// Interface defining the structure of component data
interface ComponentData {
  messages: Message[];
  newMessage: string;
  chatService: ProcessorService;
  preprocessor: Preprocessor | null;
  me: Role;
  isLoading: boolean;
  isMenuOpen: boolean;
  isTagEditorOpen: boolean;
  isCorporateAIEditorOpen: boolean;
  isSettingsOpen: boolean;
  settings: Settings;
  showTokenAlert: boolean;
  audioManager: AudioManager;
  md: MarkdownIt;
  isFilesModalOpen: boolean;
}

export default defineComponent({
  components: {
    TagEditorModal,
    CorporateAIEditorModal,
    SettingsModal,
    FilesModal
  },
  props: {
    userName: {
      type: String,
      required: true
    },
    userEmail: {
      type: String,
      required: true
    },
    token: {
      type: String,
      required: true
    }
  },
  setup() {
    const chatContent = ref<HTMLElement | null>(null);
    const $toast = useToast();
    const showTraceInfo = ref(false);
    const currentTraceInfo = ref<Message[]>([]);
    return { chatContent, $toast, showTraceInfo, currentTraceInfo };
  },
  data(): ComponentData {
    return {
      messages: [],                  // Array to store chat messages
      newMessage: '',                // Current message being typed
      chatService: new ProcessorService(this.token), // Service to handle chat operations
      preprocessor: null,            // Preprocessor for chat messages
      me: Role.User,                 // Current user's role
      isLoading: false,              // Loading state for AI responses
      isMenuOpen: false,             // State of the sidebar menu
      isTagEditorOpen: false,        // State of the tag editor modal
      isCorporateAIEditorOpen: false,
      isSettingsOpen: false,         // State of the settings modal
      settings: {
        openAiApiKey: '',
        chatModel: 'gpt-4o-mini',
        taggingModel: 'gpt-4o-mini'
      },
      showTokenAlert: true,
      // Initialize AudioManager with placeholder values, to be set in created() hook
      audioManager: new AudioManager('', ''),
      md: new MarkdownIt(),
      isFilesModalOpen: false,
    };
  },
  computed: {
    // Expose Role enum to the template
    Role() {
      return Role;
    },

    // Compute whether to show the Trigger System 2 button
    showTriggerSystem2Button(): boolean {
      return !this.isLoading && this.messages.filter(m => m.role === Role.User).length > 0;
    }
  },
  async created() {
    // Initialize chat service
    await this.chatService.init();
    
    // Initialize preprocessor
    this.preprocessor = new Preprocessor(this.chatService);

    // Load settings from local storage
    const savedSettings = localStorage.getItem('settings');
    if (savedSettings) {
      this.settings = JSON.parse(savedSettings);
    }
    this.chatService.setSettings(this.settings);
    this.showTokenAlert = this.settings.openAiApiKey.length == 0;

    // Initialize AudioManager with correct values from chat service
    this.audioManager = new AudioManager(this.chatService.apiUrl, this.chatService.token);

    // Fetch all messages on startup
    await this.fetchAllMessages();
  },
  methods: {
    renderMarkdown(content: string) {
      try {
        return this.md.render(content.trim());
      } catch (e) {
        console.error('Error rendering markdown:', e);
        return content; // Return the original content if markdown rendering fails
      }
    },
    // Fetch all messages from the chat service
    async fetchAllMessages() {
      if (this.chatService) {
        try {
          this.messages = await this.chatService.getAllMessages();
          console.log('Fetched messages:', this.messages);
        } catch (error) {
          this.isLoading = false;
          this.$toast.error((error as Error).message || 'An error occurred while fetching messages', {
            position: 'top-right',
            dismissible: true,
            duration: 0
          });
        }

        // Scroll to bottom of chat after messages are loaded
        this.$nextTick(() => {
          this.scrollToBottom();
        });
      }
    },
    
    // Send a new message
    async sendMessage() {
      if (this.newMessage.trim()) {
        // Create and add user message to the chat
        const userMessage: Message = {
          id: -1,
          text: this.newMessage,
          role: Role.User,
          timestamp: new Date()
        };
        this.messages.push(userMessage);

        const prompt = this.newMessage;
        this.newMessage = '';  // Clear input field
        
        // Scroll to bottom of chat
        this.$nextTick(() => {
          this.scrollToBottom();
        });

        this.isLoading = true;

        try {
          // Send message to chat service and fetch updated messages
          await this.chatService.chat(prompt);
          await this.fetchAllMessages();
        } catch (error) {
          this.isLoading = false;
          this.$toast.error((error as Error).message || 'An error occurred while processing your message', {
            position: 'top-right',
            dismissible: true,
            duration: 0
          });
        } finally {
          this.isLoading = false;
          // Scroll to bottom of chat after new message is added
          this.$nextTick(() => {
            this.scrollToBottom();
          });
        }
      }
    },
    
    // Scroll chat to bottom
    scrollToBottom() {
      nextTick(() => {
        if (this.chatContent) {
          this.chatContent.scrollTop = this.chatContent.scrollHeight;
        }
      });
    },
    
    // Toggle sidebar menu
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    
    // Open tag editor modal
    editTags() {
      this.isMenuOpen = false;
      this.isTagEditorOpen = true;
    },
    
    // Close tag editor modal
    closeTagEditor() {
      this.isMenuOpen = false;
      this.isTagEditorOpen = false;
    },

    editCorporateAI() {
      this.isMenuOpen = false;
      this.isCorporateAIEditorOpen = true;
    },
    
    closeCorporateAIEditor() {
      this.isMenuOpen = false;
      this.isCorporateAIEditorOpen = false;
    },
    
    // Reset chat
    async reset() {
      try {
        await this.chatService.resetChat();
        await this.fetchAllMessages();
      } catch (error) {
        console.error('Failed to reset chat:', error);
        this.$toast.error((error as Error).message || 'An error occurred while triggering System 2 thinking', {
          position: 'top-right',
          dismissible: true,
          duration: 0
        });
      }
    },
    
    // Open settings modal
    openSettings() {
      this.isMenuOpen = false;
      this.isSettingsOpen = true;
    },
    
    // Close settings modal
    closeSettings() {
      this.isMenuOpen = false;
      this.isSettingsOpen = false;
    },
    
    // Save settings
    saveSettings(settings: Settings) {
      localStorage.setItem('settings', JSON.stringify(settings));
      this.settings = settings;
      this.chatService.setSettings(this.settings);
      this.showTokenAlert = this.settings.openAiApiKey.length == 0;
      this.closeSettings();
    },

    openFilesModal() {
      this.isMenuOpen = false;
      this.isFilesModalOpen = true;
    },
    
    closeFilesModal() {
      this.isMenuOpen = false;
      this.isFilesModalOpen = false;
    },

    openTraceInfo(message: Message) {
      if (message.traceInfo && message.traceInfo.length) {
        this.currentTraceInfo = message.traceInfo;
        this.showTraceInfo = true;
      }
    },

    closeTraceInfo() {
      this.showTraceInfo = false;
      this.currentTraceInfo = [];
    },

    handleOutsideClick(event: MouseEvent) {
      if (this.isMenuOpen && !(event.target as Element).closest('.sidebar')) {
        this.isMenuOpen = false;
      }
      if (this.showTraceInfo && !(event.target as Element).closest('.trace-info-panel') && !(event.target as Element).closest('.trace-info-icon')) {
        this.closeTraceInfo();
      }
    },
    
    // Toggle audio streaming
    toggleAudioStreaming() {
      if (this.audioManager.streaming) {
        this.audioManager.stopStreaming();
      } else {
        this.audioManager.startStreaming();
      }
    },

    // Method to handle Trigger System 2 button click
    async triggerSystem2() {
      try {
        this.isLoading = true;
        await this.chatService.triggerSystem2();
        await this.fetchAllMessages();
      } catch (error) {
        this.isLoading = false;
        this.$toast.error((error as Error).message || 'An error occurred while triggering System 2 thinking', {
          position: 'top-right',
          dismissible: true,
          duration: 0
        });
      } finally {
        this.isLoading = false;
        this.$nextTick(() => {
          this.scrollToBottom();
        });
      }
    }
  },
});
</script>


<style scoped>
/* Main chat container */
#dev-chat {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Chat container */
#chat {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

/* Chat content area */
.chat-content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 1rem;
  padding-top: 40px; /* Space for the menu toggle */
}

/* Chat messages list */
.chat-messages {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

/* Individual message styling */
.message {
  position: relative;
  max-width: 80%;
  padding: 10px;
  border-radius: 15px;
  margin-bottom: 10px;
  word-wrap: break-word;
}

/* Bot message styling */
.bot-message {
  align-self: flex-start;
  background-color: #f0f0f0;
  color: black;
}

/* User message styling */
.user-message {
  align-self: flex-end;
  background-color: #007bff;
  color: white;
}

/* Message content container */
.message-content {
  padding-right: 25px; /* Make room for the icon */
}

/* Tags container */
.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-top: 4px;
}

/* Individual tag styling */
.tag {
  font-size: 0.75rem;
  padding: 2px 6px;
  border-radius: 12px;
  background-color: rgba(0, 0, 0, 0.1);
  color: inherit;
}

/* Trace info icon */
.trace-info-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  font-size: 18px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-message .trace-info-icon {
  background-color: rgba(0, 0, 0, 0.1);
  color: white;
}

/* Chat input area */
.chat-input {
  display: flex;
  padding: 1rem;
  border-top: 1px solid #ddd;
  background-color: #ffffff;
}

/* Chat input field */
.chat-input input {
  flex-grow: 1;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

/* Chat input button */
.chat-input button {
  margin-left: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Loading message styling */
.loading-message {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  opacity: 0.7;
}

/* Loading indicator animation */
.loading-indicator {
  width: 20px;
  height: 20px;
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-right: 10px;
}

/* Menu toggle button */
.menu-toggle {
  position: fixed;
  top: 10px;
  left: 10px;
  font-size: 24px;
  cursor: pointer;
  z-index: 1001;
  background-color: white;
  padding: 5px;
  border-radius: 5px;
  user-select: none;
}

/* Sidebar styling */
.sidebar {
  position: fixed;
  left: -200px;
  top: 0;
  width: 200px;
  height: 100vh;
  background-color: rgba(240, 240, 240, 0.9);
  transition: left 0.3s ease;
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

/* Open sidebar */
.sidebar.open {
  left: 0;
}

/* Sidebar buttons */
.sidebar button {
  margin: 10px;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Audio streaming button */
.sidebar button.audio-stream {
  background-color: #28a745;
}

/* Trigger System 2 button */
.trigger-system-2-button {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

/* API key alert */
.api-key-alert {
  background-color: #ff4d4f;
  color: white;
  padding: 10px;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

/* TraceInfo side panel */
.trace-info-panel {
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  height: 100vh;
  background-color: #f8f8f8;
  border-left: 1px solid #ddd;
  padding: 20px;
  overflow-y: auto;
  z-index: 1000;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
}

.trace-messages {
  list-style-type: none;
  padding: 0;
}

.trace-message {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
}

.trace-message.user-message {
  background-color: #007bff;
}

.trace-message.assistant-message {
  background-color: #f0f0f0;
}

/* Animations */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all 0.3s ease;
}

.slide-fade-enter-from, .slide-fade-leave-to {
  transform: translateX(300px);
  opacity: 0;
}

/* Hover effects */
.trace-info-icon:hover,
.sidebar button:hover,
.chat-input button:hover,
.trigger-system-2-button:hover {
  opacity: 0.8;
}

.sidebar button:active,
.chat-input button:active,
.trigger-system-2-button:active {
  transform: scale(0.95);
}
</style>