<template>
  <DevChat v-if="isAuthorized" :userName="userName" :userEmail="userEmail" :token="token"/>
  <LoginScreen v-else @auth-success="handleAuthSuccess"/>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DevChat from './DevChat.vue';
import LoginScreen from './LoginScreen.vue';
import './App.vue.css';

// Interface defining the structure of component data
interface ComponentData {
  isAuthorized: boolean;
  userName: string;
  userEmail: string;
  token: string;
}

export default defineComponent({
  components: {
    DevChat,
    LoginScreen
  },
  data(): ComponentData {
    return {
      isAuthorized: false,
      userName: '',
      userEmail: '',
      token: ''
    };
  },
  methods: {
    async handleAuthSuccess(token: string, user: { name: string; email: string }) {
      this.isAuthorized = true;
      this.userName = user.name;
      this.userEmail = user.email;
      this.token = token;
    },
  }
});
</script>