import { AIService } from "./AIService";

interface StatementStatus {
    updated_utterance: string;
    is_complete: boolean;
}

export class Preprocessor {

    private aiService: AIService;

    constructor(aiService: AIService) {
        this.aiService = aiService;
    }

    public async readyToSend(candidate: string): Promise<boolean> {
        const evalPrompt = `generate json that indicates whether or not a statement looks like a complete statement 
        suitable to be evaluated as a prompt. allow for missing punctuation (consider the statement with whatever 
        punctuation would have been most likely to follow). here is an example of the json: { "is_complete": true } 
        and here is the statement "${candidate}"`

        const result = await this.aiService.generateResponse(evalPrompt, "generate");
        const ready = await this.parseJsonFromMarkdown(result);
        console.log(`"${candidate}" -> ${result} -? ${ready}`);

        // Check if 'ready' is not null and has a valid 'is_complete' property
        if (ready !== null && typeof ready.is_complete === "boolean") {
            return ready.is_complete; // Return the value of 'is_complete'
        }

        return false;
    }

    public async evaluateUtterance(accumulated: string, additional: string): Promise<StatementStatus | null> {
        const evalPrompt = `

        Evaluate how best to append an additional string of text to an already accumulated string of text.

        The text is being acquired by continuously recognizing speech. As new text arrives, you need to decided how
        it should be appended. The accumulated part may have already terminated with punctuation. And, once the new
        additional text arrives, that earlier punctuation choice may need to be reconsidered.
        
        Here is the already accumulted text: "${accumulated}"
        Here is the additional text: "${additional}"

        Fill in the combined text in most sensible way and indicated if updated_utterance is a complete prompt ready for processing.

        If the additional text ends in filler words like uhm, umm, er, ahh that's probably a clue that the speaker has more to say still.

        Here is an example template of how you should respond via json only:
        { 
            "updated_utterance": "this is the text that came from combining accumulated with addition."
            "is_complete": true
        } 
        `
        const result = await this.aiService.generateResponse(evalPrompt, "generate");
        try {
            const json = this.parseJsonFromMarkdown(result);
            console.log(`"${accumulated}", "${additional}" -> ${result} -? ${json}`);
            if (json !== null && typeof json.is_complete === "boolean") {
                return json; // Return the value of 'is_complete'
            }
            return null;
        } catch (error) {
            // Handle JSON parsing errors by requesting a corrected JSON from the AI service
            console.log("Initial JSON parsing failed:", error);
            const correctionPrompt = `
                The provided JSON response appears to be malformed. Please correct the JSON syntax and return the 
                corrected version as pure JSON text. Ensure there is no additional commentary or explanations included. 
                Malformed JSON: ${result}
            `;
            const correctedResult = await this.aiService.generateResponse(correctionPrompt, "generate");
            try {
                const correctedJson = this.parseJsonFromMarkdown(correctedResult);
                console.log(`Corrected JSON attempt: ${correctedResult} -> ${correctedJson}`);
                return correctedJson;
            } catch (finalError) {
                console.error("Failed to parse corrected JSON:", finalError);
                return null;
            }
        }
    }

    private parseJsonFromMarkdown(jsonMd: string): StatementStatus | null {
        // Remove the Markdown code ticks and any leading/trailing whitespace
        const cleanedString = jsonMd.replace(/```json|```/g, '').trim();

        // Parse the cleaned JSON string into an object
        const jsonObject = JSON.parse(cleanedString);
        return jsonObject;
    }

}