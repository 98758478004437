<template>
  <div id="login-screen">
    <img src="./assets/logo.png" alt="Logo" class="centered-logo">
    <div class="logo-button-spacer"></div>
    <div class="signin-container">
      <div id="g_id_onload" data-auto_prompt="false"></div>
      <div class="g_id_signin" data-type="standard"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useToast } from 'vue-toast-notification';
import './LoginScreen.vue.css';
import axios, { AxiosError } from 'axios';

// Define types for Google Sign-In
declare global {
  interface Window {
    google?: {
      accounts: {
        id: {
          initialize: (config: {
            client_id: string;
            callback: (response: { credential: string }) => void;
          }) => void;
          renderButton: (element: HTMLElement, options: {
            theme: string;
            size: string;
          }) => void;
        };
      };
    };
  }
}

// Interface defining the structure of component data
interface ComponentData {
  clientId: string;
}

export default defineComponent({
  setup() {
    const $toast = useToast();
    return { $toast };
  },
  data(): ComponentData {
    return {
      clientId: '532849151962-gerup4k4k85s5b5n1rq7rnbq573fbgh9.apps.googleusercontent.com'
    };
  },
  mounted() {
    this.loadGoogleSignInScript();
  },
  methods: {
    loadGoogleSignInScript() {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.defer = true;
      script.onload = () => {
        this.initializeGoogleSignIn();
      };
      document.head.appendChild(script);
    },
    initializeGoogleSignIn() {
      if (window.google) {
        window.google.accounts.id.initialize({
          client_id: this.clientId,
          callback: this.handleCredentialResponse,
        });
        window.google.accounts.id.renderButton(
          document.querySelector('.g_id_signin') as HTMLElement,
          { theme: 'outline', size: 'large' }
        );
      }
    },
    async loadConfig(): Promise<string> {
        try {
            const response = await fetch('/devconfig.json'); // Adjust path as necessary
            const config = await response.json();
            if (config.developmentIP == "") {
                return `/api`;
            } else {
                return `http://${config.developmentIP}:3000/api`;
            }
        } catch (error) {
            console.error('Failed to load config:', error);
            return `/api`;
        }
    },
    async handleCredentialResponse(response: any) {
      try {
        const apiUrl = await this.loadConfig();
        // Send the token to your backend for verification
        const result = await axios.post(`${apiUrl}/login`, { token: response.credential });
        
        let email = result.data.email;
        let name = result.data.name;

        this.$emit('auth-success', response.credential, { name: name, email: email });
        
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const axiosError = error as AxiosError;

          if (axiosError.response) {
            let errorData = axiosError.response.data as any;
            this.$toast.error(errorData.error || 'An error occurred during login', {
              position: 'top-right',
              dismissible: true,
              duration: 0
            });
          }
        } else {
          this.$toast.error((error as Error).message || 'An error occurred during login', {
            position: 'top-right',
            dismissible: true,
            duration: 0
        });
        }
      }
      
    }
  }
});
</script>