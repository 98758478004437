import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DevChat = _resolveComponent("DevChat")!
  const _component_LoginScreen = _resolveComponent("LoginScreen")!

  return (_ctx.isAuthorized)
    ? (_openBlock(), _createBlock(_component_DevChat, {
        key: 0,
        userName: _ctx.userName,
        userEmail: _ctx.userEmail,
        token: _ctx.token
      }, null, 8, ["userName", "userEmail", "token"]))
    : (_openBlock(), _createBlock(_component_LoginScreen, {
        key: 1,
        onAuthSuccess: _ctx.handleAuthSuccess
      }, null, 8, ["onAuthSuccess"]))
}