export class AudioManager {
  private audioContext: AudioContext | null = null;
  private audioQueue: AudioBuffer[] = [];
  private isPlaying = false;
  private playbackInProgress = false;
  private eventSource: EventSource | null = null;
  private isStreaming = false;

  constructor(private apiUrl: string, private token: string) {}

  public startStreaming() {
    this.audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
    this.audioQueue = [];
    this.isPlaying = false;
    this.playbackInProgress = false;
    const MIN_BUFFER_LENGTH = 3;

    this.eventSource = new EventSource(`${this.apiUrl}/audio-stream?token=${this.token}`);

    this.eventSource.onmessage = async (event) => {
      const audioData = atob(event.data);
      const audioBuffer = new ArrayBuffer(audioData.length);
      const view = new Uint8Array(audioBuffer);
      for (let i = 0; i < audioData.length; i++) {
        view[i] = audioData.charCodeAt(i);
      }

      try {
        const decodedData = await this.audioContext!.decodeAudioData(audioBuffer);
        this.audioQueue.push(decodedData);

        if (!this.isPlaying && this.audioQueue.length >= MIN_BUFFER_LENGTH) {
          this.isPlaying = true;
          this.playNextChunk();
        }
      } catch (error) {
        console.error('Error decoding audio data:', error);
      }
    };

    this.eventSource.onerror = (error) => {
      console.error('Error with EventSource:', error);
      this.stopStreaming();
    };

    this.isStreaming = true;
  }

  public stopStreaming() {
    if (this.eventSource) {
      this.eventSource.close();
    }
    if (this.audioContext) {
      this.audioContext.close();
    }
    this.isStreaming = false;
    this.isPlaying = false;
    this.playbackInProgress = false;
    this.audioQueue = [];
  }

  private async playNextChunk() {
    if (this.playbackInProgress) {
      return;
    }
    if (this.audioQueue.length === 0) {
      this.isPlaying = false;
      return;
    }

    this.playbackInProgress = true;

    try {
      const audioBuffer = this.audioQueue.shift();
      if (!audioBuffer) {
        this.isPlaying = false;
        this.playbackInProgress = false;
        return;
      }

      const source = this.audioContext!.createBufferSource();
      source.buffer = audioBuffer;
      source.connect(this.audioContext!.destination);

      source.onended = () => {
        this.playbackInProgress = false;
        if (this.audioQueue.length > 0) {
          this.playNextChunk();
        } else {
          this.isPlaying = false;
        }
      };

      source.start();
    } catch (error) {
      console.error('Error during playback:', error);
      this.playbackInProgress = false;
    }
  }

  public get streaming(): boolean {
    return this.isStreaming;
  }
}