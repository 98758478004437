<template>
  <div class="modal-overlay" @click="closeModal">
    <div class="modal-content" @click.stop>
      <h2>Edit Corporate AI Configuration</h2>
      <div class="editor-container">
        <div class="json-editor">
          <textarea v-model="corporateAIJson" rows="25" cols="60"></textarea>
        </div>
        <div class="visualization">
          <div id="network" ref="networkContainer" style="width: 100%; height: 400px;"></div>
        </div>
      </div>
      <div class="generate-section">
        <textarea v-model="generatePrompt" placeholder="Enter prompt for new corp generation" class="generate-input"
          rows="3"></textarea>
        <div class="generate-options">
          <label>
            <input type="checkbox" v-model="baseOnCurrent"> Base new corp on current one
          </label>
          <button @click="generateNewCorp" class="generate-button" :disabled="isGenerating">
            <span v-if="!isGenerating">Generate New Corp</span>
            <span v-else>Generating...</span>
          </button>
        </div>
      </div>
      <div class="modal-buttons">
        <button @click="loadDefaultAI">Load Defaults</button>
        <div>
          <button @click="saveCorporateAI">Save</button>
          <button @click="closeModal">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, watch, nextTick } from 'vue';
import { ProcessorService } from './ProcessorService';
import { DataSet, Network, Options } from 'vis-network/standalone';

export default defineComponent({
  name: 'CorporateAIEditorModal',
  props: {
    processorService: {
      type: Object as () => ProcessorService,
      required: true
    }
  },
  emits: ['close'],
  setup(props, { emit }) {
    const generatePrompt = ref('');
    const corporateAIJson = ref('');
    const network = ref<Network | null>(null);
    const networkContainer = ref<HTMLElement | null>(null);
    const corporateAIData = ref<any>(null);
    const isGenerating = ref(false);
    const baseOnCurrent = ref(false);

    const generateNewCorp = async () => {
      try {
        let prompt = generatePrompt.value;
        isGenerating.value = true;
        if (baseOnCurrent.value) {
          prompt = `here is our current CorpSet: ${corporateAIJson.value}\n\n${prompt}`;
        }
        const newCorp = await props.processorService.generateNewCorp(prompt);
        corporateAIJson.value = JSON.stringify(newCorp, null, 2);
        corporateAIData.value = newCorp;

        nextTick(() => {
          if (networkContainer.value) {
            createVisualization();
          }
        });
      } catch (error) {
        console.error('Error generating new Corporate AI configuration:', error);
        alert('Failed to generate new configuration. Please try again.');
      } finally {
        isGenerating.value = false;
      }
    };

    const createVisualization = () => {
      if (!networkContainer.value || !corporateAIData.value) {
        console.error('Network container or data not available');
        return;
      }

      const nodes = new DataSet<any>([]);
      const edges = new DataSet<any>([]);

      const addNode = (id: string, label: string, level: number, group: string) => {
        nodes.add({ id, label, level, group });
      };

      const addEdge = (from: string, to: string) => {
        edges.add({ from, to });
      };

      const processManager = (manager: any, parentId: string | null, level: number) => {
        addNode(manager.id, `${manager.type}: ${manager.id}`, level, manager.type.toLowerCase());
        if (parentId) {
          addEdge(parentId, manager.id);
        }

        // Process sub-managers if they exist
        if (manager.managers && Array.isArray(manager.managers)) {
          manager.managers.forEach((subManager: any) => {
            processManager(subManager, manager.id, level + 1);
          });
        }

        // Process actors if they exist
        if (manager.actors && Array.isArray(manager.actors)) {
          manager.actors.forEach((actor: any) => {
            addNode(actor.id, `${actor.type}: ${actor.id}`, level + 1, actor.type.toLowerCase());
            addEdge(manager.id, actor.id);
          });
        }
      };

      const data = corporateAIData.value;

      // Process the CEO (top-level manager)
      processManager(data.ceo, null, 0);

      const options: Options = {
        layout: {
          hierarchical: {
            direction: 'UD',
            sortMethod: 'directed',
            levelSeparation: 150,
            nodeSpacing: 200
          }
        },
        edges: {
          smooth: {
            enabled: true,
            type: 'cubicBezier',
            forceDirection: 'vertical',
            roundness: 0.4
          }
        },
        groups: {
          ceo: { color: { background: '#FFA500', border: '#FF8C00' } },
          manager: { color: { background: '#4CAF50', border: '#45a049' } },
          actor: { color: { background: '#2196F3', border: '#1E88E5' } }
        }
      };

      if (network.value) {
        network.value.destroy();
      }
      network.value = new Network(networkContainer.value, { nodes, edges }, options);
    };

    onMounted(async () => {
      try {
        const response = await props.processorService.getCorporateAI();
        corporateAIJson.value = JSON.stringify(response.corporateAI, null, 2);
        corporateAIData.value = response.corporateAI;

        nextTick(() => {
          if (networkContainer.value) {
            createVisualization();
          }
        });
      } catch (error) {
        console.error('Error fetching Corporate AI configuration:', error);
        alert('Error fetching Corporate AI configuration.' + error);
      }
    });

    watch(corporateAIJson, (newValue) => {
      try {
        const parsedConfig = JSON.parse(newValue);
        corporateAIData.value = parsedConfig;
        nextTick(() => {
          if (networkContainer.value) {
            createVisualization();
          }
        });
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
    });

    const saveCorporateAI = async () => {
      try {
        const parsedConfig = JSON.parse(corporateAIJson.value);
        await props.processorService.setCorporateAI(parsedConfig);
        emit('close');
      } catch (error) {
        console.error('Error saving Corporate AI configuration:', error);
        alert('Invalid JSON format. Please check your input.');
      }
    };

    const loadDefaultAI = async () => {
      try {
        const response = await props.processorService.getDefaultCorporateAI();
        corporateAIJson.value = JSON.stringify(response.corporateAI, null, 2);
        corporateAIData.value = response.corporateAI;

        nextTick(() => {
          if (networkContainer.value) {
            createVisualization();
          }
        });
      } catch (error) {
        console.error('Error loading default Corporate AI configuration:', error);
        alert('Failed to load default configuration. Please try again.');
      }
    };

    const closeModal = () => {
      emit('close');
    };

    return {
      corporateAIJson,
      saveCorporateAI,
      loadDefaultAI,
      closeModal,
      network,
      networkContainer,
      generateNewCorp,
      generatePrompt,
      isGenerating,
      baseOnCurrent
    };
  }
});
</script>

<style scoped>
.generate-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.generate-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.generate-input {
  width: 100%;
  margin-bottom: 10px;
  flex-grow: 1;
  margin-right: 10px;
  padding: 5px;
  resize: vertical;
  /* Allow vertical resizing */
  min-height: 60px;
  /* Set a minimum height */
  max-height: 200px;
  /* Set a maximum height */
}

.generate-button {
  padding: 5px 10px;
  position: relative;
  min-width: 120px;
  height: 60px;
  /* Match the initial height of the textarea */
}

.generate-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* Add a subtle animation for the loading state */
@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.generate-button:disabled span {
  animation: pulse 1.5s infinite;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  max-height: 90%;
  width: 90%;
  height: 90%;
  overflow-y: auto;
  z-index: 2001;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.editor-container {
  display: flex;
  flex-grow: 1;
  margin-bottom: 10px;
}

.json-editor,
.visualization {
  flex: 1;
  margin: 0 10px;
}

textarea {
  width: 100%;
  height: 100%;
  resize: none;
  box-sizing: border-box;
  padding: 8px;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
</style>