<template>
    <div class="modal-overlay" @click="closeModal">
      <div class="modal-content" @click.stop>
        <div class="modal-header">
          <h2>Your Text Files</h2>
          <button @click="closeModal" class="close-button">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
          </button>
        </div>
        
        <div v-if="isLoading" class="loading">Loading...</div>
        <div v-else-if="error" class="error">{{ error }}</div>
        <div v-else class="modal-body">
          <div class="file-list">
            <div v-for="file in files" :key="file.id" class="file-item">
              <span 
                @click="selectFile(file)" 
                :class="{ 'selected': selectedFile?.id === file.id }"
              >
                {{ file.name }}
              </span>
              <div class="file-actions">
                <button @click="renameFile(file)" :disabled="isProcessing" class="icon-button">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                </button>
                <button @click="removeFile(file)" :disabled="isProcessing" class="icon-button">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg>
                </button>
              </div>
            </div>
            <button @click="addNewFile" :disabled="isProcessing" class="add-file-button">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
              Add New File
            </button>
          </div>
          
          <div v-if="selectedFile" class="file-content">
            <h3>{{ selectedFile.name }}</h3>
            <textarea 
              v-model="selectedFile.content" 
              rows="10" 
              :disabled="isProcessing"
            ></textarea>
            <button @click="saveFileContent" :disabled="isProcessing" class="save-button">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path><polyline points="17 21 17 13 7 13 7 21"></polyline><polyline points="7 3 7 8 15 8"></polyline></svg>
              Save Changes
            </button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref, onMounted } from 'vue';
  import { ProcessorService } from './ProcessorService';
  
  interface TextFile {
    id: string;
    name: string;
    content: string;
  }
  
  export default defineComponent({
    name: 'UserFilesModal',
    props: {
      processorService: {
        type: Object as () => ProcessorService,
        required: true
      }
    },
    emits: ['close'],
    setup(props, { emit }) {
      const files = ref<TextFile[]>([]);
      const selectedFile = ref<TextFile | null>(null);
      const isLoading = ref(false);
      const isProcessing = ref(false);
      const error = ref('');
  
      onMounted(async () => {
        await fetchFiles();
      });
  
      const fetchFiles = async () => {
        isLoading.value = true;
        error.value = '';
        try {
          const response = await props.processorService.getUserFiles();
          files.value = response.files.map(file => ({
            id: file.id,
            name: file.name,
            content: ''
          }));
        } catch (err) {
          error.value = 'Failed to fetch files. Please try again.';
          console.error('Error fetching user files:', err);
        } finally {
          isLoading.value = false;
        }
      };
  
      const selectFile = async (file: TextFile) => {
        isProcessing.value = true;
        error.value = '';
        try {
          const response = await props.processorService.getFileContent(file.id);
          selectedFile.value = { ...file, content: response.content };
        } catch (err) {
          error.value = 'Failed to load file content. Please try again.';
          console.error('Error fetching file content:', err);
        } finally {
          isProcessing.value = false;
        }
      };
  
      const addNewFile = async () => {
        const name = prompt('Enter the name for the new file:');
        if (name) {
          isProcessing.value = true;
          error.value = '';
          try {
            await props.processorService.addUserFile(name);
            await fetchFiles();
          } catch (err) {
            error.value = 'Failed to add new file. Please try again.';
            console.error('Error adding new file:', err);
          } finally {
            isProcessing.value = false;
          }
        }
      };
  
      const removeFile = async (file: TextFile) => {
        if (confirm(`Are you sure you want to delete "${file.name}"?`)) {
          isProcessing.value = true;
          error.value = '';
          try {
            await props.processorService.removeUserFile(file.id);
            if (selectedFile.value?.id === file.id) {
              selectedFile.value = null;
            }
            await fetchFiles();
          } catch (err) {
            error.value = 'Failed to remove file. Please try again.';
            console.error('Error removing file:', err);
          } finally {
            isProcessing.value = false;
          }
        }
      };
  
      const renameFile = async (file: TextFile) => {
        const newName = prompt('Enter new file name:', file.name);
        if (newName && newName.trim() && newName !== file.name) {
          isProcessing.value = true;
          error.value = '';
          try {
            await props.processorService.renameUserFile(file.id, newName);
            await fetchFiles();
            if (selectedFile.value?.id === file.id) {
              selectedFile.value.name = newName;
            }
          } catch (err) {
            error.value = 'Failed to rename file. Please try again.';
            console.error('Error renaming file:', err);
          } finally {
            isProcessing.value = false;
          }
        }
      };
  
      const saveFileContent = async () => {
        if (selectedFile.value) {
          isProcessing.value = true;
          error.value = '';
          try {
            await props.processorService.saveUserFileContent(selectedFile.value.id, selectedFile.value.content);
          } catch (err) {
            error.value = 'Failed to save file content. Please try again.';
            console.error('Error saving file content:', err);
          } finally {
            isProcessing.value = false;
          }
        }
      };
  
      const closeModal = () => {
        emit('close');
      };
  
      return {
        files,
        selectedFile,
        isLoading,
        isProcessing,
        error,
        selectFile,
        addNewFile,
        removeFile,
        renameFile,
        saveFileContent,
        closeModal
      };
    }
  });
  </script>
  
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
  }
  
  .modal-content {
    background-color: white;
    border-radius: 8px;
    width: 80%;
    max-width: 800px;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid #e2e8f0;
  }
  
  .modal-body {
    display: flex;
    flex: 1;
    overflow: hidden;
  }
  
  h2, h3 {
    margin: 0;
    color: #333;
  }
  
  .close-button {
    background: none;
    border: none;
    cursor: pointer;
    color: #4a5568;
  }
  
  .file-list {
    width: 40%;
    border-right: 1px solid #e2e8f0;
    overflow-y: auto;
    padding: 1rem;
  }
  
  .file-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
    border-bottom: 1px solid #e2e8f0;
  }
  
  .file-item:last-child {
    border-bottom: none;
  }
  
  .file-item span {
    cursor: pointer;
    flex-grow: 1;
  }
  
  .file-item span.selected {
    font-weight: bold;
    color: #3182ce;
  }
  
  .file-actions {
    display: flex;
    gap: 0.5rem;
  }
  
  .icon-button {
    background: none;
    border: none;
    cursor: pointer;
    color: #4a5568;
  }
  
  .icon-button:hover {
    color: #2d3748;
  }
  
  .icon-button:disabled {
    color: #a0aec0;
    cursor: not-allowed;
  }
  
  .add-file-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0.5rem;
    margin-top: 1rem;
    background-color: #3182ce;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .add-file-button:hover {
    background-color: #2c5282;
  }
  
  .add-file-button:disabled {
    background-color: #a0aec0;
    cursor: not-allowed;
  }
  
  .file-content {
    flex: 1;
    padding: 1rem;
    display: flex;
    flex-direction: column;
  }
  
  textarea {
    flex: 1;
    margin: 1rem 0;
    padding: 0.5rem;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    resize: none;
  }
  
  .save-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    background-color: #3182ce;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .save-button:hover {
    background-color: #2c5282;
  }
  
  .save-button:disabled {
    background-color: #a0aec0;
    cursor: not-allowed;
  }
  
  .loading, .error {
    text-align: center;
    padding: 1rem;
  }
  
  .error {
    color: #c53030;
    background-color: #fff5f5;
    border: 1px solid #feb2b2;
    border-radius: 4px;
  }
  
  @media (max-width: 768px) {
    .modal-content {
      width: 95%;
      max-height: 95vh;
    }
  
    .modal-body {
      flex-direction: column;
    }
  
    .file-list {
      width: 100%;
      border-right: none;
      border-bottom: 1px solid #e2e8f0;
      max-height: 40vh;
    }
  
    .file-content {
      max-height: 50vh;
    }
  }
  </style>